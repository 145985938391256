// import { useEffect } from 'react'

import Header from '../../../components/Header'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import PictureTextLink from '../../../components/PictureTextLink'
import Footer from '../../../components/Footer'
// import Button from '../../../components/Button'
import BannerTestimonie from '../../../components/BannerTestimonie'

function LifeCoaching() {
	// const buttonStageId = 'buttonHomeStage'
	const pageId = 33

	// Button animation DÉCOUVREZ NOS STAGES
	// useEffect(() => {
	// 	const buttonStageElement = document.getElementById(buttonStageId)

	// 	if (typeof IntersectionObserver !== 'undefined') {
	// 		const observer = new IntersectionObserver((entries) => {
	// 			if (entries[0].isIntersecting) {
	// 				buttonStageElement.classList.add(
	// 					'SOUKIASSIAN-Home-ButtonCTA-stage-viewed',
	// 				)
	// 			} else {
	// 				buttonStageElement.classList.remove(
	// 					'SOUKIASSIAN-Home-ButtonCTA-stage-viewed',
	// 				)
	// 			}
	// 		})

	// 		observer.observe(buttonStageElement)

	// 		return () => observer.disconnect()
	// 	} else {
	// 		buttonStageElement.classList.add(
	// 			'SOUKIASSIAN-Home-ButtonCTA-stage-viewedIsNotAvailable',
	// 		)
	// 	}
	// }, [])

	return (
		<div className="SOUKIASSIAN-LifeCoaching">
			<Header type="finalPage" title="COACHER SA VIE" />
			{/* <Button
				id={buttonStageId}
				className="SOUKIASSIAN-Home-ButtonCTA SOUKIASSIAN-Home-ButtonCTA-stage col-xxs-11 col-xs-11 col-6"
				link="/stages"
			>
				DÉCOUVREZ NOS STAGES
			</Button> */}
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="STAGES"
				subTitle="SAGESSE, MÉDITATION, CONSCIENCE..."
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={1}
					link="/stages"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="SÉANCE"
				subTitle="COMMUNICATION CONSCIENTE"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={2}
					link="/session-life-coaching"
					animate
					flipped
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="FORMATION"
				subTitle="COMMUNICATION D'ÉCOUTE ET D'EXPRESSION NON BLESSANTES"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={3}
					promo={50}
					link="/training-life-coaching-first-module"
					animate
					flipped
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="FORMATION"
				subTitle="COMMUNICATION CONSCIENTE"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={4}
					link="/training-life-coaching"
					width="calc(100% + 26px)"
					animate
					flipped
				/>
			</HarmoniousTitle>
			<Footer />
		</div>
	)
}

export default LifeCoaching
