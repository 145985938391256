import { useEffect } from 'react'

import BannerThreePicturesLink from '../../components/BannerThreePicturesLink'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PictureTextLink from '../../components/PictureTextLink'
import HarmoniousTitle from '../../components/HarmoniousTitle'
import BannerText from '../../components/BannerText'
import Button from '../../components/Button'

import './index.scss'

function Coaching() {
	const buttonStageId = 'buttonHomeStage'
	const pageId = 36

	useEffect(() => {
		const buttonStageElement = document.getElementById(buttonStageId)

		if (typeof IntersectionObserver !== 'undefined') {
			const observer = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					buttonStageElement.classList.add(
						'SOUKIASSIAN-Home-ButtonCTA-stage-viewed',
					)
				} else {
					buttonStageElement.classList.remove(
						'SOUKIASSIAN-Home-ButtonCTA-stage-viewed',
					)
				}
			})

			observer.observe(buttonStageElement)

			return () => observer.disconnect()
		} else {
			buttonStageElement.classList.add(
				'SOUKIASSIAN-Home-ButtonCTA-stage-viewedIsNotAvailable',
			)
		}
	}, [])

	return (
		<div className="SOUKIASSIAN-Coaching">
			<Header type="finalPage" title="LES ACCOMPAGNEMENTS." />
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle"
				title="LES SÉANCES"
			>
				<BannerThreePicturesLink
					picture1={{
						title: 'SÉANCE COMMUNICATION CONSCIENTE',
						pageId: pageId,
						entityId: 1,
						link: '/session-life-coaching',
						flipped: true,
					}}
					picture2={{
						title: 'SÉANCE COACHING PLEINE CONSCIENCE',
						pageId: pageId,
						entityId: 2,
						link: '/session-become-coach',
						flipped: true,
					}}
					picture3={{
						title: 'SÉANCE COACHING PROFESSIONNEL',
						pageId: pageId,
						entityId: 3,
						link: '/session-business-coaching',
					}}
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle"
				title="LES FORMATIONS"
			>
				<BannerThreePicturesLink
					picture1={{
						title: 'FORMATION COMMUNICATION CONSCIENTE',
						pageId: pageId,
						entityId: 4,
						link: '/training-life-coaching',
						flipped: true,
					}}
					picture2={{
						title: 'FORMATION COACHING PLEINE CONSCIENCE',
						pageId: pageId,
						entityId: 5,
						link: '/training-become-coach',
					}}
					picture3={{
						title: 'FORMATION COACHING PROFESSIONNEL',
						pageId: pageId,
						entityId: 6,
						link: '/training-business-coaching',
						flipped: true,
					}}
				/>
			</HarmoniousTitle>

			<Button
				id={buttonStageId}
				className="SOUKIASSIAN-Home-ButtonCTA SOUKIASSIAN-Home-ButtonCTA-stage col-xxs-11 col-xs-11 col-6"
				link="/stages"
			>
				DÉCOUVREZ NOS STAGES
			</Button>

			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="LES FORMATIONS DE FORMATEURS"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					title="DEVENEZ FORMATEUR"
					pageId={pageId}
					entityId={7}
					link="/trainer-trainings"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			<Footer />
		</div>
	)
}

export default Coaching
