import Header from '../../components/Header'
import BannerText from '../../components/BannerText'
import BannerThreePicturesLink from '../../components/BannerThreePicturesLink'
import HarmoniousTitle from '../../components/HarmoniousTitle'
import Footer from '../../components/Footer'

import './index.scss'

function Home() {
	const pageId = 2

	return (
		<div className="SOUKIASSIAN-Home">
			<Header
				type="home"
				pageId={pageId}
				entityId={1}
				title="Découvrez une séance offerte !"
				subTitle={
					<>
						<br />
						Osez une vie authentique.
						<br />
						Transformez vos difficultés.
						<br />
						Brillez en pleine conscience.
					</>
				}
				/*buttonText="NOUS REJOINDRE LORS D'UN ÉVÉNEMENT"*/
				buttonText="CONTACTEZ-NOUS"
			/>

			<BannerText
				className="SOUKIASSIAN-Home-headerSubTitle"
				title={
					<>
						<span>Développez le potentiel véritable</span>
					</>
				}
				textNoBg
			>
				<b>Cette année</b>, revenons à l'essentiel de notre humanité.
				<br />
				<span
					style={{
						margin: '5px 0 15px 30px',
						letterSpacing: '30px',
						fontSize: '2rem',
					}}
				>
					...
				</span>
				<b>Avançons ensemble</b>, avec bienveillance, vers vos projets.
				<br />
				<span
					style={{
						margin: '5px 0 15px 30px',
						letterSpacing: '30px',
						fontSize: '2rem',
					}}
				>
					...
				</span>
				<b>Construisons</b>, un quotidien harmonieux et inspirons les
				autres.
			</BannerText>

			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle"
				title="STAGES, SÉANCES ET FORMATIONS"
			>
				<BannerThreePicturesLink
					picture1={{
						title: 'COACHER SA VIE',
						pageId: pageId,
						entityId: 2,
						flipped: true,
						link: '/life-coaching',
					}}
					picture2={{
						title: 'DEVENIR COACH',
						pageId: pageId,
						entityId: 3,
						link: '/become-coach',
						flipped: true,
					}}
					picture3={{
						title: "COACHING D'ENTREPRISE",
						pageId: pageId,
						entityId: 4,
						link: '/business-coaching',
						flipped: true,
					}}
					bordered
				/>
			</HarmoniousTitle>

			<BannerText textNoBg>
				<b>Découvrez de nouvelles perspectives</b>, explorez vos
				potentiels avec Christophe Soukiassian et sa méthode L.C.C. ©.
				<br />
				<br />
			</BannerText>
			<BannerText>
				Grâce aux accompagnements de l'école, avancez vers vos
				aspirations et <b>libérez votre plein potentiel</b>.
			</BannerText>

			{/* <TestimoniesHome
				pageId={pageId}
				entityId={6}
				entityIdList={[7, 8, 9]}
			/> */}

			<Footer />
		</div>
	)
}

export default Home
